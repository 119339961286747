const options = {
    hooks: {},
    navbar: {
        add: true,
        title: 'Menu',
        titleLink: 'parent'
    },
    slidingSubmenus: true
};
export default options;
