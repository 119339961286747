const options = {
    collapsed: {
        use: false,
    },
    expanded: {
        use: false,
        initial: 'open'
    }
};
export default options;
